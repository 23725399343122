<template>
  <div class="group" v-if="!isProcessing">
    <div class="group__menu" v-for="item in items" :key="item.title">
      <group-menu :item="item" />
    </div>
  </div>
</template>

<script>
import GroupMenu from '@/components/common/menu.vue'

export default {
  name: 'group',
  components: { GroupMenu },
  data () {
    return {
      items: [
        { title: '未加入ユーザー', content: 'FBグループに未加入なユーザー情報を表示します。', to: 'group_new' },
        { title: '退会済みユーザー', content: 'LDWから退会したユーザー情報を表示します。', to: 'group_cancel' },
        { title: '加入済みユーザー', content: 'Facebookグループに加入済みのユーザー情報を表示します。', to: 'group_member' }

      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.group {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
}
</style>
